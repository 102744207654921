export interface ProcessedGoods {
  checked?: boolean;
  id: number;
  name: string;
  number: number;
  retail_price?: number;
  termUnit?: string;
  termUnitName?: string;
}

export interface StepOneFormState {
  domain: {
    value: string;
    isChecked: boolean;
  };
  goodsList: ProcessedGoods[] | undefined;
  terms: {
    agree1: boolean;
    agree2: boolean;
    agree3: boolean;
  };
}

export interface TerminationFormState {
  authenticateState: {
    isAuthenticated: boolean;
    target: {
      owner: boolean;
      manager: boolean;
    };
    method: {
      sms: boolean;
      email: boolean;
    };
    authValue: number | null;
    ownerMobile: string;
    ownerEmail: string;
    managerMobile: string;
    managerEmail: string;
  };
  revokeState: {
    bankName: string;
    accountNumber: string;
    accountName: string;
  };
  isAgree: boolean;
}

export const enum TerminationType {
  SERVICE = 'service',
  LICENSE = 'license',
}

export interface AutoPayInfo {
  auto_pay_info_seqno: number; // 결제수단 시퀀스
  alias: string; // 별칭
  pay_method: string; //	결제 수단 구분  ex) R(계좌), C(카드)
  pay_name: string; //	결제 수단 명칭 ex) 카드, 계좌
  pc_code: string; //	결제사회사코드(은행코드 or 카드사코드)
  card_bank_cd: string; //	은행카드일 경우 은행코드 ex) 카카오체크카드 일경우 pc_code=kb카드 코드,  card_bank_cd=카카오뱅크코드, 현대카드 일경우 pc_cd=현대카드코드, card_bank_cd=null
  card_no: string; //	카드번호 뒤4자리
  company_name: string; //	결제사 단축 회사명 ex) 현대카드일 경우 현대, KB은행 일경우 KB
  company_name_full: string; //	결제사 회사명 ex) 현대카드, KB은행
  img_src: string; //	결제사 로고 파일명 ex)  이미지 경로: https://static.gabia.com/responsive/assets/images/payment/logo/{img_src}
  reqbill: string; //	증빙신청 여부 ex) 0:증빙없음, 1:세금계산서신청 5:현금영수증신청
  billtax_info_seqno: number; //	세금계산서 정보 시퀀스- 세금계산서 신청일 경우
  waiting_cnt: number; //	결제수단 연결된 서비스중 처리대기인 서비스 갯수
  default_yn: string; //	기본 결제 수단 (결제수단 구분별 하나씩) Y, N
  pg_id: string; //	pg사 아이디
  auth_key: string; //	결제수단 인증번호
  is_online: string; //	실시간결제 여부  ex) Y,N
  auto_pay_service_seq: number; //	대표서비스 정기결제 시퀀스
  standard_type: string; //	대표서비스 결제표준화 여부 ex) Y,N
  service_seqno: string; //	대표서비스 서비스 시퀀스
  domain: string; //	대표서비스 도메인명
  service_status: string; //	대표서비스 서비스 상태
  carve_code: string; //	대표서비스 서비스코드
  expiredate: string; //	대표서비스 만기일 yyyy-mm-dd
  gtype: string; //	대표서비스 상품타입
  std_price: number; //	대표서비스 월기준가(vat 포함)
  std_price_vat_ex: number; //	대표서비스 월기준가(vat 미포함)
  pay_type: string; //	대표서비스 결제방식타입 ex) PA:선납정기, DA:후납정기, DP:후납일반 , PP:선납일반
  pay_term: number; //	대표서비스 정기결제 신청기간 ex)1 :1개월, 12:12개월
  carve_name: string; //	대표서비스 서비스명
  pay_date: string; //	대표서비스 정기결제 예정일
  month_discount_price: number; //	대표서비스 1개월 할인금액
  year_discount_price: number; //	대표서비스 12개월 할인금액
  max_autopay_service_seq: number; //	결제수단에 연결된 서비스중 가장 마지막 신청된 서비스의 정기결제 시퀀스
  auto_pay_service: AutoPayService[]; // 연결된 서비스의 정기결제 정보 리스트
}

export interface AutoPayService {
  service: string;
  auto_pay_service_seq: number; //	정기결제 시퀀스
  auto_pay_info_seqno: number; //	정기결세 신청된 결제수단 시퀀스
  standard_type: string; //	결제표준화 여부 Y:표준화,N:비표준화
  pay_type: string; //	서비스 결제방식타입 ex) PA:선납정기, DA:후납정기, DP:후납일반 , PP:선납일반
  pay_term: string; //	정기결제 신청기간 ex)1 :1개월, 12:12개월
  service_seqno: number[]; //	서비스 시퀀스
  domain: string; //	도메인
  service_status: string; //	서비스 상태
  carve_code: string; //	서비스 코드
  carve_name: string; //	서비스명
  gtype: string; //	상품 타입
  std_price: string; //	서비스 월기준가(vat 포함)
  std_price_vat_ex: string; //	서비스 월기준가(vat 미포함)
  expiredate: string; //	만기일 yyyy-mm-dd
  pay_date: string; //	우편번호 ex) 11111
  month_discount_price: string; //	1개월 할인금액
  year_discount_price: string; //	12개월 할인금액
  discount_period: string; //
}

export type NimsAdditionalGoods = Record<
  number,
  {
    list: Array<AddtionalNimsGood>;
    checked: boolean;
    checkboxDisabled: boolean;
    selectedId: string;
    modalId: string;
    goodsGroup: { expose_name: string; group_id: number; name: string; sort_order: number; code: string };
  }
>;

export interface AddtionalNimsGood {
  expose_name: string;
  goods: object;
  goods_group: object;
  goods_id: string;
  release_date: string;
  sort_order: number;
}
