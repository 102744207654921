
import BillLayout from '@/layouts/BillLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import Vue, { PropType } from 'vue';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import InfoModal from '@/components/common/InfoModal/InfoModal.vue';
import MessageBox from '@/components/common/MessageBox.vue';
import { TerminationFormState } from '@/types/common/goods';

export default Vue.extend({
  components: { BillLayout, FormLayout, ColumnLayout, TableLayout, InfoModal, MessageBox },
  model: {
    prop: 'formState',
    event: 'change',
  },
  props: {
    formState: {
      type: Object as PropType<TerminationFormState>,
    },
    templateState: {
      type: Object as any,
    },
    errors: {
      type: Object as any,
    },
  },
  data() {
    return {
      formTitle,
    };
  },
  computed: {
    isNextStepBtnDisabled(): boolean {
      return !!(
        this.formState.authenticateState.isAuthenticated &&
        this.formState.revokeState.bankName &&
        this.formState.revokeState.accountNumber &&
        this.formState.revokeState.accountName &&
        this.formState.isAgree
      );
    },
  },
  methods: {
    onClickOpenAuthenticateModal() {
      this.$GabiaModal.show('authenticate-info-modal');
    },
    onChaneModalRadioInput(e) {
      const name = e.name;
      const detail = e.detail;

      const authenticateValue = Object.entries(this.formState.authenticateState[`${name}`]).reduce(
        (obj, [key, value]) => {
          obj[`${key}`] = false;
          return obj;
        },
        {},
      );

      this.$emit('change', {
        ...this.formState,
        authenticateState: {
          ...this.formState.authenticateState,
          [name]: {
            ...authenticateValue,
            [detail]: true,
          },
        },
      });
    },
    onAgreeChecked(value) {
      this.$emit('change', {
        ...this.formState,
        isAgree: value,
      });
    },
    onClickSendAuthValue() {
      this.$emit('click-send-auth-value-btn');
    },
    onInputAuthValue(e) {
      this.$emit('change', {
        ...this.formState,
        authenticateState: {
          ...this.formState.authenticateState,
          authValue: e.target.value.replace(/[^0-9]/, ''),
        },
      });
    },
    onClickAuthCheckBtn() {
      this.$emit('click-auth-check-btn');
    },
    onCliclNextStepBtn() {
      this.$emit('click-next-step-btn');
    },
    onInputRevokeState(e) {
      this.$emit('change', {
        ...this.formState,
        revokeState: {
          ...this.formState.revokeState,
          [e.target.name]: e.target.value,
        },
      });
    },
  },
});
