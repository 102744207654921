
import MicrosoftTermination01Template from '@/templates/ms365/MicrosoftTermination01Template.vue';
import {
  sendAuthTokenAndGetAuthToken,
  isAuthValueConincide,
  getUserDetail,
  getHostingDetail,
  getServiceRevokeInfo,
  getLicenseRevokeInfo,
  revokeService,
  revokeLicense,
} from '@/api/proxy/gapi';
import { RouterType } from '@/router/types';
import { TerminationFormState, TerminationType } from '@/types/common/goods';
import Vue from 'vue';

export default Vue.extend({
  components: {
    MicrosoftTermination01Template,
  },
  data() {
    return {
      formState: {
        authenticateState: {
          isAuthenticated: false,
          target: {
            owner: true,
            manager: false,
          },
          method: {
            sms: true,
            email: false,
          },
          authValue: null,
          ownerMobile: '',
          ownerEmail: '',
          managerMobile: '',
          managerEmail: '',
        },
        revokeState: {
          bankName: '',
          accountNumber: '',
          accountName: '',
        },
        isAgree: false,
      } as TerminationFormState,
      templateState: {
        service: '',
        domain: '',
        license: {
          value: '',
          warning: '',
        },
      },
      errors: {
        authenticate: null as any,
      },
      authenticate: {
        auth_type: 'owner' as 'owner' | 'manager',
        cert_type: 'sms' as 'sms' | 'email',
        auth_token: '',
      },
    };
  },
  computed: {
    serviceSeq(): number {
      return this.$store.state.goodsInfo.serviceId;
    },
    terminationType(): TerminationType {
      return this.$store.state.goodsInfo.type;
    },
  },
  async created() {
    const hostingDetail = await getHostingDetail(this.serviceSeq);

    if (!hostingDetail) {
      alert('잘못된 접근입니다.');
      window.location.href = process.env.VUE_APP_GABIA_URL;
      return;
    }

    const [userDetail, serviceRevokeInfo, licenseRevokeInfo] = await Promise.all([
      getUserDetail(),
      getServiceRevokeInfo(this.serviceSeq),
      getLicenseRevokeInfo(this.serviceSeq),
    ]);

    this.$console.log(serviceRevokeInfo);
    this.$console.log(licenseRevokeInfo);

    if (this.terminationType === TerminationType.SERVICE) {
      // 서비스 해지
      if (!serviceRevokeInfo) {
        alert('해지 가능한 서비스가 없습니다.');
        window.location.href = process.env.VUE_APP_GABIA_URL;
      }
      this.templateState.license.value = ` ${serviceRevokeInfo?.order.name} ${serviceRevokeInfo?.order.license_count}개`;
      this.templateState.license.warning = `${this.getDiffTimeStringFromNow(
        serviceRevokeInfo.order.revoke_expire_date,
      )} 후 해지 불가`;
    } else if (this.terminationType === TerminationType.LICENSE) {
      // 추가 라이선스 해지
      if (!licenseRevokeInfo) {
        alert('해지 가능한 라이선스가 없습니다.');
        window.location.href = process.env.VUE_APP_GABIA_URL;
      }
      this.templateState.license.value = ` ${licenseRevokeInfo?.order.name} ${licenseRevokeInfo?.order.license_count}개`;
      this.templateState.license.warning = `${this.getDiffTimeStringFromNow(
        licenseRevokeInfo.order.revoke_expire_date,
      )} 후 해지 불가`;
    } else {
      alert('잘못된 접근입니다.');
      window.location.href = process.env.VUE_APP_GABIA_URL;
    }

    this.formState.authenticateState.ownerMobile = userDetail?.hp_no || '';
    this.formState.authenticateState.ownerEmail = userDetail?.email || '';
    this.formState.authenticateState.managerMobile = hostingDetail?.b_mobile || '';
    this.formState.authenticateState.managerEmail = hostingDetail?.b_email || '';

    this.templateState.service = hostingDetail?.carve_name || '';
    this.templateState.domain = hostingDetail?.domain || '';

    this.$store.commit('setTerminationInfo', {
      msoffice365: {
        service: this.templateState.service,
        domain: this.templateState.domain,
        license: this.templateState.license,
      },
    });
    // this.templateState.license = serviceRevokeInfo
    //   ? ` ${serviceRevokeInfo?.order.name} ${serviceRevokeInfo?.order.license_count}개`
    //   : '';

    this.$console.log(hostingDetail);
    this.$GlobalLoading.hide();
  },
  methods: {
    getDiffTimeStringFromNow(dateStr) {
      const start = Date.now();
      const end = new Date(dateStr).getTime();

      const diff = end - start;

      const hourDiff = Math.floor(diff / (1000 * 60 * 60));
      const minDiff = Math.floor((diff - hourDiff * (1000 * 60 * 60)) / (1000 * 60));

      this.$console.log(hourDiff);
      this.$console.log(minDiff);

      return `${hourDiff === 0 ? '' : `${hourDiff}시간 `}${minDiff}분`;
    },
    async onClickSendAuthValueBtn() {
      this.$console.log('전송');
      this.errors.authenticate = {
        type: 'loading',
        message: '잠시만 기다려주세요.',
      };
      const auth_type = Object.entries(this.formState.authenticateState.target).find(([_, value]) => {
        return value === true;
      })?.[0] as 'owner' | 'manager';
      const cert_type = Object.entries(this.formState.authenticateState.method).find(([_, value]) => {
        return value === true;
      })?.[0] as 'email' | 'sms';

      // 인증 번호를 전송한 때 지정한 타입을 저장해놓기 위함.
      this.authenticate.auth_type = auth_type;
      this.authenticate.cert_type = cert_type;

      if (auth_type && cert_type) {
        try {
          const { auth_token } = await sendAuthTokenAndGetAuthToken({
            service_seq: this.serviceSeq,
            auth_type,
            cert_type,
          });
          this.authenticate.auth_token = auth_token;
          if (auth_token) {
            this.errors.authenticate = {
              type: 'info',
              message: '인증번호가 정상적으로 발송되었습니다. 입력 후 확인을 눌러주세요.',
            };
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          /**
           * 에러를 재현해볼수가 없어 이런식으로 대응
           */
          let message = '불특정 에러가 발생했습니다. 고객센터에 연락해주시기 바랍니다. (1544-4370)';

          if (error?.message) {
            message = error.message;
          }

          alert(message);
        }
      }
    },
    async onClickAuthCheckBtn() {
      this.$console.log('체크');

      if (!this.formState.authenticateState.authValue) {
        this.errors.authenticate = {
          type: 'error',
          message: '인증번호를 입력해 주세요.',
        };
        return;
      }

      const authData = await isAuthValueConincide({
        service_seq: this.serviceSeq,
        auth_type: this.authenticate.auth_type,
        cert_type: this.authenticate.cert_type,
        auth_value: this.formState.authenticateState.authValue || -1,
        auth_token: this.authenticate.auth_token,
      });

      if (authData && authData.auth_token === this.authenticate.auth_token) {
        this.formState.authenticateState.isAuthenticated = true;
        this.errors.authenticate = {
          type: 'info',
          message: '본인 인증 완료',
        };
        this.$GabiaModal.hide('authenticate-info-modal');
      } else {
        this.errors.authenticate = {
          type: 'error',
          message: '인증번호가 다릅니다. 다시 입력해 주세요.',
        };
      }
    },
    async onClickNextStepBtn() {
      this.$GlobalLoading.show();
      let result = null as any;

      if (this.terminationType === TerminationType.SERVICE) {
        result = await revokeService({
          auth_token: this.authenticate.auth_token,
          account_number: this.formState.revokeState.accountNumber,
          account_name: this.formState.revokeState.accountName,
          bank_name: this.formState.revokeState.bankName,
        });
      } else if (this.terminationType === TerminationType.LICENSE) {
        result = await revokeLicense({
          auth_token: this.authenticate.auth_token,
          account_number: this.formState.revokeState.accountNumber,
          account_name: this.formState.revokeState.accountName,
          bank_name: this.formState.revokeState.bankName,
        });
      }

      if (result?.isSuccess) {
        this.$router.push({ name: `${RouterType.TERMINATION}-02`, params: { service_code: 'msoffice365' } });
      } else {
        alert('해지 신청에 실패하였습니다.');
        if (
          process.env.VUE_APP_MODE !== 'production' &&
          confirm('test: 해지 신청에 실패하였으나 해지 신청 완료 페이지로 이동해볼까요?')
        ) {
          this.$router.push({ name: `${RouterType.TERMINATION}-02`, params: { service_code: 'msoffice365' } });
        }
      }
      this.$GlobalLoading.hide();
    },
  },
});
