<template>
  <div class="message-box">
    <div class="message" :class="classObject" v-if="item">
      <div class="icon">
        <i v-if="item.type === 'info'" class="fas fa-check-circle info"></i>
        <i v-if="item.type === 'error'" class="fas fa-exclamation-circle error"></i>
        <i v-if="item.type === 'loading'" class="fas fa-spinner loading"></i>
      </div>
      <div class="text">
        {{ item.message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      classObject: {
        error: this.item?.type === 'error',
        info: this.item?.type === 'info',
      },
    };
  },
  watch: {
    item(newValue) {
      this.classObject = {
        error: newValue?.type === 'error',
        info: newValue?.type === 'info',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: none;
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.message {
  display: flex;
  align-items: center;
  padding-top: 10px;
  /* font-size: 16px; */
  color: var(--blue);
  i {
    font-size: 22px;
    margin-right: 5px;
  }
  i.loading {
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .text {
    white-space: pre-line;
  }
}

.message.error {
  color: var(--orange);
}
</style>
